module.exports = [{
      plugin: require('../node_modules/@snek-at/jaen/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@snek-at/jaen-pages/gatsby-browser.js'),
      options: {"plugins":[],"templates":{"SamplePage":"/home/runner/work/jaen-template/jaen-template/src/templates/SamplePage.tsx"}},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
